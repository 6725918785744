.orgsActions {
  display: flex;
  justify-content: space-between;
}

.workspaceActions {
  display: flex;
  justify-content: space-between;
}

.moduleActions {
  display: flex;
  justify-content: space-between;
}

.createOrganization{
  width: 50%;
  margin: auto;
  margin-top: 20px;
}

.ant-tabs-nav-list {
  padding-right: 50px;
}

.setting{
  margin-left: 40px;
  margin-right: 80px;
}
.workspaceWrapper{
  width: 80%;
  margin: auto;
  margin-top: 20px;
}

.workspaceList{
  width: 100%;
  margin-top:30px;
}