.login-wrapper {
  width: 328px;
  margin:0px auto;
  padding-top:200px;
}

.login-container{
  background-image: url(https://gw.alipayobjects.com/zos/rmsportal/TVYTbAXWheQpRcWDaDMu.svg);
  background-repeat: no-repeat;
  background-position: center 110px;
  background-size: 100%;
  height: 100vh;
}

.loginLogo {
 width: 230px;
 text-align: center;

}
