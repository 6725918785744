.orgsActions {
  display: flex;
  justify-content: space-between;
}

.workspaceActions {
  display: flex;
  justify-content: space-between;
}

.moduleActions {
  display: flex;
  justify-content: space-between;
}

.createOrganization{
  width: 50%;
  margin: auto;
  margin-top: 20px;
}

.ant-tabs-nav-list {
  padding-right: 80px;
}

.setting{
  margin-left: 40px;
  margin-right: 100px;
  padding-bottom: 100px;
}

.addVCS{
  float: right;
}

.link{
  padding: 0;
}

.vcsList{
  width: 100%;
}
.editor{
  border: 1px solid #eee;
}

ul.disc-list {
  list-style-type: disc
}